import { getExistingApiInstance } from '@/api'

export default function useRequest() {
  async function getRequest(id) {
    const api = getExistingApiInstance()
    const { data } = await api.request.getOne(id, { include: 'form' })
    return data
  }
  async function getUserRequest(id) {
    const api = getExistingApiInstance()
    const { data } = await api.userRequest.getOne(id, { include: 'fields' })
    return data
  }
  async function getUserRequests(id, userId, category, idCoauthors) {
    const api = getExistingApiInstance()
    const params = {
      filter: {
        'request.id': id,
        'request.category': category,
        id: idCoauthors,
      },
      perPage: 0,
      include: 'request',
    }
    const { data } = await api.userRequest.getMany(params)
    return data
  }
  async function deleteUserRequests(id) {
    const api = getExistingApiInstance()
    const { data } = await api.userRequest.delete(id)
    return data
  }
  async function createRequest(params) {
    const api = getExistingApiInstance()
    const { data } = await api.formRecord.createRequest(params)
    return data
  }
  async function createUserRequest(id, email) {
    const api = getExistingApiInstance()
    const userData = {
      email: email,
      request_user_id: id,
    }
    const { data } = await api.userRequestCoauthor.create(userData)
    return data
  }

  return {
    getRequest,
    getUserRequest,
    getUserRequests,
    deleteUserRequests,
    createRequest,
    createUserRequest,
  }
}
