<template>
  <div class="request-item">
    <div class="request-item__table">
      <div class="request-item__row">
        <div class="request-item__head">ID</div>
        <div
          class="request-item__content"
          style="display: flex; justify-content: space-between"
        >
          {{ data.requestId }}
          <!--
            <Button
                    v-if="!dateExpired"
                    type="error"
                    size="small"
                    class="request-item__delete"
                    ghost
                    @click="deleteUserRequest">
                <Icon type="ios-trash-outline" />
            </Button>
            <span
                    v-if="!dateExpired"
                    class="request-item__remove s-pointer"
                    @click="deleteUserRequest">
                {{ $tr('request.thesis.remove') }}
            </span>
            -->
        </div>
      </div>
      <div class="request-item__row">
        <div class="request-item__head">
          {{ $tr(getRequestI18n('requestType')) }}
        </div>
        <div class="request-item__content">
          {{ data.name }}
        </div>
      </div>
      <div class="request-item__row">
        <div class="request-item__head">
          {{ $tr('request.thesis.requestAlertStatus') }}
        </div>
        <div class="request-item__content">
          <div class="request-item__status" :class="statusClass(data.status)">
            {{ statusText(data.status) }}
          </div>
        </div>
      </div>
      <div v-if="data.comment" class="request-item__row">
        <div class="request-item__head">
          {{ $tr('request.comment') }}
        </div>
        <div class="request-item__content">
          {{ data.comment }}
        </div>
      </div>
      <div v-if="isCoAuthor" class="request-item__row">
        <div class="request-item__head">
          {{ $tr('request.thesis.addCoauthor') }}
        </div>
        <div class="request-item__content input">
          <input
            ref="inputCoAuthors"
            v-model="emailCoAuthors"
            class="request-item__input"
            :placeholder="$tr('request.thesis.addCoauthorEmail')"
            required
          />
          <button
            class="request-item__button"
            :disabled="checkEmailCoAuthors"
            @click="sendCoAuthorsRequest"
          >
            <span>
              <Icon
                class="request-item__icon"
                type="md-arrow-forward"
                :size="20"
              />
            </span>
          </button>
        </div>
      </div>
      <div v-if="showAuthor" class="request-item__row">
        <div class="request-item__head">
          {{ $tr('request.thesis.coauthor') }}
        </div>
        <div class="request-item__content">
          {{ showAuthorName }}
        </div>
      </div>
      <div class="request-item__row">
        <div class="request-item__head">
          {{ $tr(getRequestI18n('requestDate')) }}
        </div>
        <div class="request-item__content">
          {{ fromISOToString(data.createdAt) }}
        </div>
      </div>
    </div>
    <div
      class="card-list-item__btn-block"
      style="display: flex; justify-content: right"
    >
      <span
        v-if="!dateExpired"
        class="sent-requests__remove s-pointer"
        @click="deleteUserRequest"
        >{{ $tr('request.thesis.remove') }}</span
      >

      <span class="sent-requests__open s-pointer" @click="openUserRequest">{{
        $tr('request.thesis.open')
      }}</span>
    </div>
  </div>
</template>
<script>
import { fromISOToString } from '@/utils'
import { REQUESTS_TYPE_MAP, REQUESTS_TYPE_COLOR_MAP } from '@/utils/constants'
import loginService from '@/domain/services/login-service/login-service'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    category: { type: String, default: '' },
    dateExpired: { type: Boolean, default: false },
  },
  data() {
    return {
      emailCoAuthors: '',
    }
  },
  computed: {
    isCoAuthor() {
      return this.data.isCoauthor
    },
    showAuthor() {
      return this.data.coAuthor
    },
    showAuthorName() {
      const authors = []
      for (const key in this.showAuthor) {
        authors.push(
          ' ' +
            this.data.coAuthor[key].firstName +
            ' ' +
            this.data.coAuthor[key].lastName,
        )
      }
      return authors.toString()
    },
    checkEmailCoAuthors() {
      return !loginService.validateEmail(this.emailCoAuthors)
    },
  },
  methods: {
    fromISOToString,
    getRequestI18n(key) {
      return `request.${this.category}.` + key
    },
    statusClass(type) {
      return `request-item__status--${REQUESTS_TYPE_COLOR_MAP[type]}`
    },
    statusText(status) {
      return this.$tr(`request.status.${REQUESTS_TYPE_MAP[status]}`)
    },
    deleteUserRequest() {
      this.$emit('del-request', this.data.requestId)
    },
    openUserRequest() {
      this.$emit('open-request', this.data.requestId)
    },
    sendCoAuthorsRequest() {
      this.$emit('send-coauthors', {
        id: this.data.requestId,
        email: this.emailCoAuthors,
      })
      this.emailCoAuthors = null
    },
  },
}
</script>
<style lang="scss">
.request-item {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  overflow: hidden;
  word-break: break-word;

  &__table {
    text-align: left;
    width: 100%;
    @include media-breakpoint-down(sm) {
      padding: 6px 0px;
    }
  }

  &__row {
    display: flex;

    &:first-child {
      > .card-list-item__head {
        padding: 16px 24px 0 24px;
        border-radius: 5px 0 0 0;
      }

      > .card-list-item__content {
        padding: 16px 24px 0 24px;
      }
    }

    &:last-child {
      > .card-list-item__head {
        padding: 0 24px 16px 24px;
        border-radius: 0 0 0 5px;
      }

      > .card-list-item__content {
        padding: 0 24px 16px 24px;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 5px 18px;
    }
  }

  &__head {
    min-width: 220px;
    background-color: var(--s-black-color-5);
    padding: 14px 20px;
    letter-spacing: 0.1px;
    font-family: var(--s-semi-bold-font-font);
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    @include media-breakpoint-down(sm) {
      min-width: initial;
      background-color: #fff;
      padding: 0;
    }
  }

  &__content {
    flex-grow: 1;
    white-space: normal;
    padding: 14px 20px;
    color: var(--var-black-80);
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    font-family: var(--s-regular-font);
    font-weight: 400;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  &__status {
    &--warning {
      color: var(--s-warning-color);
    }

    &--danger {
      color: var(--s-error-color);
    }

    &--success {
      color: var(--s-success-color);
    }
  }
  .ivu-icon {
    color: #fff;
    font-size: 20px;
  }
  &__input {
    width: 80%;
    display: inline-block;
    width: 100%;
    font-size: 14px;
    border: 1px solid var(--s-black-color-60);
    border-radius: 5px;
  }
  &__input:focus {
    outline: none !important;
    border: 1px solid var(--s-black-color-60);
  }
  &__input:hover {
    border-color: var(--s-black-color-80);
  }

  &__button {
    width: 20%;
    cursor: pointer;
    background-color: var(--s-primary-color-70);
    border-color: var(--s-primary-color-70);
    height: 32px;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-left: 8px;
    transition:
      color 0.2s linear,
      background-color 0.2s linear,
      border 0.2s linear,
      box-shadow 0.2s linear;

    &[disabled],
    &[disabled] .ivu-icon {
      cursor: default;
      background-color: var(--s-black-color-80);
    }
    &[disabled]:hover {
      background-color: var(--s-black-color-80);
    }
  }
  &__button:hover {
    background-color: var(--s-primary-color);
    border-color: var(--s-primary-color);
  }

  .input {
    display: flex;
    width: 80%;
    justify-content: start;
  }
  &__icon {
    color: var(--s-white-color);
  }
  &__delete i:before {
    color: #fb4e4e;
  }

  &__remove {
    color: #a0a4a8;
  }
  &__remove:hover {
    color: #52575c;
  }
}

.sent-requests {
  &__remove {
    position: absolute;
    left: 0;
  }
}
</style>
